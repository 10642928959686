<template>
	<div class="asset-info-container">
		<b-row>
			<b-col
				lg="4"
				md="6"
				sm="12"
				class=""
			>
				<h1 class="text-capitalize">{{getSelectedAsset.asset_type}} {{getSelectedAsset.asset_number}}</h1>
				<h2>Contrato: {{getSelectedAsset.contract_id}}</h2>
				<h3>{{getSelectedAsset.project_name}}</h3>
				<h4>Orientacion: {{getSelectedAsset.orientation}}</h4>
				<h4>Piso: {{getSelectedAsset.floor}}</h4>
			</b-col>
			<b-col
				lg="8"
				md="6"
				sm="12"
			>
				<div class="mobysuite-aftersale-client-project-card-image">
					<img :src="getImageURL(getSelectedProject.project_id)" class="mobysuite-aftersale-client-project-image">
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'AssetInfo',
	computed: {
		...mapGetters('assets', [
            'getSelectedAsset',
			'getImageURL',
			'getSelectedProject',
        ]),

	}
}
</script>

<style scoped>
.mobysuite-aftersale-client-project-card-image{
	display: flex;
	justify-content: right;
	align-items: center;
	padding-right: 5%;
	margin-bottom: -50px;
	height: 100%;
	max-width: 100%;
}

.mobysuite-aftersale-client-project-image{
	max-width: 30%;
	max-height: 55%;
}

@media only screen and (max-width: 599px) {

	.mobysuite-aftersale-client-project-card-image{
		justify-content: center;
		padding-right: auto;
		margin-bottom: 0px;
	}
	.mobysuite-aftersale-client-project-image{
		max-width: 80%;
		max-height: 80%;
	}

}

@media only screen and (min-width: 600px) and (max-width: 1000px) {

	.mobysuite-aftersale-client-project-card-image{
		justify-content: center;
		padding-right: auto;
	}
	.mobysuite-aftersale-client-project-image{
		max-width: 55%;
		max-height: 55%;
	}

}
</style>