<template>
  <div class="mt-4 pr-4 request-container">
    <h1>Agendamientos</h1>
    <b-tabs pills content-class="mt-3" class="requests-tabs">
      <b-tab title="Activos">
        <b-row
          class="mb-5"
        >
          <b-col cols="12" class="my-1" v-if="schedulings.length > 0">
            <b-form-group
              label="Buscar: "
              label-for="filter-input"
              label-cols-sm="7"
              label-align-sm="right"
              label-size="sm"
              class="mb-3"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Escribe para buscar: "
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="schedulings.length > 0">
            <b-table
              class="data-table data-table-request"
              striped
              hover
              bordered
              responsive
              show-empty
              :items="schedulings"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPageActive"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered(schedulings)"
              empty-filtered-text="No hay datos que concuerden con tu búsqueda"
            >
              <template #cell(start_date)="row">
                <b-row
                  class="justify-content-md-center text-center"
                >
                  <b-col
                    sm="6"
                  >
                    <p>{{formatDateAssigned(row["item"].start_date)}}</p>
                  </b-col>
                </b-row>
              </template>
              <template #cell(end_date)="row">
                <b-row
                  class="justify-content-md-center text-center"
                >
                  <b-col
                    sm="6"
                  >
                    <p>{{formatDateAssigned(row["item"].end_date)}}</p>
                  </b-col>
                </b-row>
              </template>
              <template #cell(re-schedule)="row">
                <b-row
                  class="justify-content-md-center text-center"
                >
                  <b-col
                    sm="6"
                  >
                    <p style="display: none;"> {{row}} </p>
                    <button type="submit" class="mt-2 mb-3 btn-primary" style="width: 50px !important; min-width: 0px !important;" @click="automaticScheduling(row['item'].id, row['item'.start_date])">
                      <b-icon icon="calendar2-date" scale="1"></b-icon>
                      </button>
                  </b-col>
                </b-row>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageActive"
              :total-rows="onFiltered(schedulings)"
              :per-page="perPage"
              aria-controls="my-table"
              class="mt-4 customPaginationHistorical"
            >
            </b-pagination>
          </b-col>
          <b-col v-else>
            <p class="text-center">Por el momento no hay agendamientos activos disponibles</p>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <automatic-scheduling
      v-show="getShowAutomaticScheduling"
      scheduleTitle="reagendamiento"
      type="re_request"
      ref="date"
    >
    </automatic-scheduling>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import AutomaticScheduling from '@/components/AutomaticScheduling.vue'
export default {
  name: "Schedulings",
  data() {
    return {
      filter: null,
      filterOn: [],
      tab: 1,
      perPage: 6,
      currentPageActive: 1,
      currentPageFinished: 1,
      currentPageHistorical: 1,
      fields: [
        {
          key: "folio",
          label: "Folio de solicitud",
          sortable: false,
        },
        {
          key: "start_date",
          label: "Fecha de inicio:",
          sortable: true,
          formatter: "formatDateAssigned"
        },
        {
          key: "end_date",
          label: "Fecha de expiración:",
          sortable: true,
        },
        {
          key: "re-schedule",
          label: "Reagendar",
          sortable: false,
        },
      ],
    };
  },
  components: {
    AutomaticScheduling,
  },
  methods: {
    ...mapMutations('elements', [
      'setSelectedPlace',
      'setSelectedItem',
      'setSelectedDetail',
      'setSelectedProblem',
      'setProcessStage',
      'setAddedElements',
      'setAddedRequirements',
    ]),
    ...mapMutations('general', [
      'setShowSuccessView',
      'setShowAutomaticScheduling',
      'setMenuOption',
      'setRequestId'
    ]),
    rows(data) {
      return data.length;
    },
    onFiltered(filteredItems) {
      this.currentPage = 1;
      return this.rows(filteredItems);
    },
    formatDateAssigned(value) {
      let current_date = value.substring(0, 10)
      let hours = value.substring(11, 19)
      return `${current_date} ${hours}`
    },
    automaticScheduling(requestId, schedulingDate){
      this.setSelectedPlace(null)
      this.setSelectedItem(null)
      this.setSelectedDetail(null)
      this.setSelectedProblem(null)
      this.setAddedElements([])
      this.setAddedRequirements([])
      this.setRequestId(requestId, schedulingDate)
      this.setShowAutomaticScheduling(true)
      this.$refs.date.date = null
    },
  },
  props: {
    schedulings: { type: Array, default: null },
  },
  computed: {
    ...mapGetters("assets", [
      "getFetchedRequestsElementsById",
      'getSelectedAsset',
      'getSelectedProject',
    ]),
    ...mapGetters("general", [
      'getShowAutomaticScheduling',
    ])
  },
};
</script>

<style>
.customPaginationHistorical > li > button {
  color: black !important;

  border: 0px !important;
  margin: 0px !important;
}

.customPaginationHistorical > li.active > button {
  border: 0px;
  color: black !important;
  background-color: rgba(223, 237, 241, 1) !important;
}
.customPaginationHistorical > li.disabled > button {
  border: 0px;
  color: red !important;
  background-color: rgba(223, 237, 241, 1) !important;
}
.customPaginationHistorical > li {
  margin: 0px !important;
  border: 0px !important;
  background-color: transparent !important;
}

.page-link {
  background-color: transparent !important;
}

@media (max-width: 800px) {
  .page-link {
    padding: 8px !important;
  }
}
</style>
