<template>
  <div :style="(contractId || isInAfterSaleRoom) ? 'background-color: white !important;' : 'background-color: rgb(229, 229, 229) !important;'">
    <Main />
  </div>
</template>

<script>
import Main from '@/views/Main.vue'
import { mapActions, mapMutations } from 'vuex'


export default {
  name: 'App',
  components: {
    Main,
  },
  props: {
    customer: { type: String, default: null },
    customerId: { type: Number, default: null },
    contractId: { type: Number, default: null },
    enableLessee: { type: Boolean, default: false },
    isInAfterSaleRoom: { type: Boolean, default: false },
  },
  created(){
    this.setCustomer(this.customer)
    this.setCustomerId(this.customerId)
    this.setContractId(this.contractId)
    this.setIsInAfterSaleRoom(this.isInAfterSaleRoom)
    this.setEnableLessee(this.enableLessee)
    this.useCustomLoginImage(this.customerId)
  },
  methods: {
    ...mapMutations('general', [
      'setCustomer',
      'setCustomerId',
      'setContractId',
      'setIsInAfterSaleRoom',
      'setEnableLessee',
    ]),
    ...mapActions('general', [
      'useCustomLoginImage'
    ])
  },
}
</script>
<style lang="scss">

mobysuite-aftersale-client {
  @import "assets/scss/bootstrap";
  @import "assets/scss/main";
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 1em !important;
  line-height: 1.42857143 !important;
  color: #333 !important;

  footer {
    position: relative !important;
  }
  
  .default .card-body{
    flex: 1 1 auto !important;
    min-height: 1px !important;
    padding: 1.25rem !important;
  }


  table{
    display: table !important;
  }

  ul li {
    list-style-type: none !important;
  }

  svg {
    display: inline !important;
  }

  input, button, select, optgroup, textarea {
    margin: 0 !important;
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }

  label{
    font-size: 1em !important;
    color: black !important;
  }

  .input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .card {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    min-width: 0 !important;
    word-wrap: break-word !important;
    background-clip: border-box !important;
  }
  .card body{
    padding: 0 !important;
  }

  .h1 {
    font-size: 2.5rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
  }

  .input-group-text {
    display: flex !important;
    align-items: center !important;
    padding: 0.375rem 0.75rem !important;
    margin-bottom: 0 !important;
    font-size: 1em !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    text-align: center !important;
    white-space: nowrap !important;
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
  }

  .input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: 10px !important;
  }

  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: stretch !important;
    width: 100% !important;
  }

  input[type=search]{
    width: 50% !important;
  }

  .row {
    position: relative !important;
    overflow-x: visible !important;
  }

  .row:before,
  .row:after {
    position: absolute !important;
    content: "" !important;
    z-index: -1 !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
  }
  .tabs .nav-tabs .nav-link.active {
    background-color: transparent !important;
    border: 0px !important;
    color: $primary-color !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    border-bottom: 2px solid $primary-color !important;
    border-radius: 0 !important;
  }
  .tabs .nav-tabs .nav-link {
    border: 0px !important;
    background-color: transparent !important;
    color: #C0BFC5 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    border-radius: 0 !important;
  }
  mobysuite-aftersale-client .navbar {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 0.5rem 1rem !important;
  }
  .navbar.navbar-expand-lg {
    padding: 15px 0 15px 0 !important;
    position: relative !important;
    z-index: 999 !important;
    background-color: #fff !important;
    width: 100% !important;
    margin: auto !important;
    margin-top: 15px !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row !important;
    align-items: flex-end !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    z-index: 1000 !important;
    float: left !important;
    font-size: 1rem !important;
    color: #212529 !important;
    text-align: left !important;
    list-style: none !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem !important;
    height: auto !important;
    top: 100% !important;
  }
  .dropdown-toggle::after {
    display: inline-block !important;
    margin-left: 0.255em !important;
    vertical-align: 0.255em !important;
    content: "" !important;
    border-top: 0.3em solid !important;
    border-right: 0.3em solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0.3em solid transparent !important;
  }
  .customPaginationHistorical > li > button {
    color: black !important;
    border: 0px !important;
    margin: 0px !important;
  }

  .customPaginationHistorical > li.active > button
  {
    border: 0px;
    color: black !important;
    background-color: rgba(223, 237, 241, 1) !important;
  }
  .customPaginationHistorical > li.disabled > button
  {
    border: 0px;
    color: red !important;
    background-color: rgba(223, 237, 241, 1) !important;
  }
  .customPaginationHistorical > li
  {
    margin: 0px !important;
    border: 0px !important;
    background-color: transparent !important;
  }

  .page-link{
    background-color: transparent !important;
  }

  @media (max-width: 800px) {
    .page-link{
      padding: 8px !important;
    }
  }
  .nav-pills .nav-link.active{
    color: #fff !important;
    background-color: #00718C !important;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem !important;
    font-size: 1.25rem !important;
    line-height: 1 !important;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 0.25rem !important;
    margin-left: 10px !important;
  }
}
</style>
