export default {
		auth: require('@websanova/vue-auth/drivers/auth/devise.js'),
		http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
		router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
    loginData: {url: 'customers/auth/sign_in', method: 'POST', redirect: '', fetchUser: true},
    registerData: {url: 'customers/auth', method: 'POST', redirect: ''},
    logoutData: {url: 'customers/auth/sign_out', method: 'DELETE', redirect: '', makeRequest: false},
    fetchData: {url: 'customers/auth/validate_token', method: 'GET', enabled: true},
    refreshData: {url: 'customers/user_token/', method: 'GET', enabled: false, interval: 0},
}
