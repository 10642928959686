<template>
  <transition name="mobysuite-aftersale-client-modal-fade">
    <div class="mobysuite-aftersale-client-modal-backdrop">
      <div
        class="mobysuite-aftersale-client-modal"
        role="dialog"
        aria-labelledby="mobysuite-aftersale-client-modalTitle"
        aria-describedby="mobysuite-aftersale-client-modalDescription"
      >
        <header
          class="mobysuite-aftersale-client-modal-header"
          id="mobysuite-aftersale-client-modalTitle"
        >
          <button
            type="button"
            class="mobysuite-aftersale-client-btn-close"
            @click="actionOnErrorType()"
            aria-label="Close mobysuite-aftersale-client-modal"
          >
            x
          </button>
        </header>

        <section
          class="mobysuite-aftersale-client-modal-body"
          id="mobysuite-aftersale-client-modalDescription"
        >
          <slot name="body">
            <b-row class="justify-content-md-center">
              <b-col cols="12" style="text-align: center">
                <b-icon
                  icon="x-circle"
                  class="error-view-icon-style"
                ></b-icon>
                <p class="mt-4">
                  {{ message }}
                </p>
              </b-col>
            </b-row>
          </slot>
        </section>

        <footer class="mobysuite-aftersale-client-modal-footer">
          <slot name="footer"> </slot>
          <b-row class="justify-content-md-center text-center px-4">
            <b-col
              lg="3"
              sm="6"
              class="py-3"
            >
              <button
                type="button"
                class="btn-primary"
                @click="actionOnErrorType()"
                aria-label="Close mobysuite-aftersale-client-modal"
              >
                {{ buttonMessage }}
              </button>
            </b-col>
          </b-row>
        </footer>
      </div>
    </div>
  </transition>
</template>
  
  <script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ErrorView",
  data() {
    return {};
  },
  created() {
  },
  props: {
    title: { type: String, default: null },
    message: { type: String, default: null },
    buttonMessage: { type: String, default: null },
    errorType: { type: String, default: null },
  },
  computed: {
    ...mapGetters("assets", ["getSelectedAsset", "getSelectedProject"]),
    ...mapGetters("general", ["getClientRecoverPassEmail", "getSupportEmail"]),
  },
  methods: {
    ...mapMutations("elements", [
      "setSelectedPlace",
      "setSelectedItem",
      "setSelectedDetail",
      "setSelectedProblem",
      "setProcessStage",
      "setAddedElements",
      "setAddedRequirements",
    ]),
    ...mapMutations("general", [
      "setShowErrorView",
      "setShowAutomaticScheduling",
      "setMenuOption",
      "setShowRecoverPassword",
    ]),
    ...mapActions("assets", [
      "getRequestsByAsset",
      "getAllScheduledDatesByAsset",
    ]),
    actionOnErrorType() {
      if (this.errorType == "profile") {
        this.setMenuOption("profile");
      }
      if (this.errorType == "recoverPassword") {
        this.close();
        this.setShowRecoverPassword(false);
      }
      this.close();
    },
    showAutomaticSchedulingModal() {
      this.setSelectedPlace(null);
      this.setSelectedItem(null);
      this.setSelectedDetail(null);
      this.setSelectedProblem(null);
      this.setAddedElements([]);
      this.setAddedRequirements([]);
      this.getRequestsByAsset({
        asset: this.getSelectedAsset.asset_number,
        project: this.getSelectedProject.project_id,
      });
      this.close();
      this.setShowAutomaticScheduling(true);
    },
    close() {
      this.$emit("close");
      this.setShowErrorView(false);
    },
  },
};
</script>
  
  <style>
.error-view-icon-style {
  transform: scale(8);
  margin: 45px;
  color: red;
}
</style>