import Vue from 'vue'
const state = {
	projectData: null,
	fetchedProjects: null,
	fetchedRequests: null,
	selectedProject: null,
	selectedAsset: null,
	isAllVitalDataSelected: false,
	scheduledDatesByAsset: [],
}
const mutations = {
	setProjectData: (state, payload) => (state.projectData = payload),
	setFetchedProjects: (state, payload) => (state.fetchedProjects = payload),
	setSelectedProject: (state, payload) => (state.selectedProject = payload),
	setSelectedAsset: (state, payload) => (state.selectedAsset = payload),
	setIsAllVitalDataSelected: (state, payload) => (state.isAllVitalDataSelected = payload),
	setFetchedRequests: (state, payload) => (state.fetchedRequests = payload),
	setScheduledDatesByAsset: (state, payload) => (state.scheduledDatesByAsset = payload),
}
const actions = {
	async fetchData( { commit }, {contractId} ){
		let url = (contractId != null ? `frontal_requests/get_frontal_data?contract_id=${contractId}` : 'frontal_requests/get_frontal_data')
		await Vue.axios
		.get(url)
		.then(response => {
			if(!response.data) {
				console.log('No projects found')
				return
			}
			let project_data = []
			project_data.push(response.data['project_data'])
			commit('setProjectData', (contractId == null ? response.data['project_data'] : project_data))
			commit('setFetchedProjects', response.data['projects'])
		})
		.catch(error => {
			console.log(error)
		})
	},
	async getRequestsByAsset( { commit }, {asset, project} ){
		await Vue.axios
			.get(`frontal_requests/get_frontal_requests?asset_id=${asset.asset_id}&asset_type=${asset.asset_type}&asset_number=${asset.asset_number}&project_name=${project.project_name}&project_id=${project.project_id}`)
			.then(response => {
				if(!response.data) {
					console.log('Error')
					return
				}
				commit('setFetchedRequests', response.data)
				commit('setIsAllVitalDataSelected', true)
				commit('general/setShowSuccessView', false, { root: true })
			})
			.catch(error => {
				console.log(error)
			})
	},
	resetAssetsOnLogOut( { commit } ){
		commit('setProjectData', null)
		commit('setFetchedProjects', null)
		commit('setSelectedProject', null)
		commit('setSelectedAsset', null)
		commit('setIsAllVitalDataSelected', false)
		commit('setFetchedRequests', null)
	},
	async getAllScheduledDatesByAsset( { commit }, {asset_id} ){
		await Vue.axios
		.get(`frontal_requests/get_scheduled_dates_by_asset?asset_id=${asset_id}`)
		.then(response => {
			if(!response.data) {
				console.log('Error')
				return
			}
			commit('setScheduledDatesByAsset', response.data.scheduled_dates)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
		})
	},
}
const getters = {
	getProjectData: state => state.projectData,
	getSelectedProject: state => state.selectedProject,
	getSelectedAsset: state => state.selectedAsset,
	getIsAllVitalDataSelected: state => state.isAllVitalDataSelected,
	getProjectById: state => id => state.fetchedProjects.find(project => project['project_id'] == id),
	getImageURL: (state, getters) => id => {
		const project = getters.getProjectById(id)
		const imageURL = project.url_logo
			? `https://s3.amazonaws.com/gestion.mobysuite/${project.url_logo}`
			: 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png'
		return imageURL
	},
	getFetchedRequests: state => state.fetchedRequests,
	getFetchedRequestsElementsById: state => (id, type) => state.fetchedRequests[type][id],
	getScheduledDatesByAsset: state => state.scheduledDatesByAsset,
}
export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
