<template>
	<transition 
		name="mobysuite-aftersale-client-modal-fade"
	>
		<div 
			class="mobysuite-aftersale-client-modal-backdrop"
		>
			<div
				class="mobysuite-aftersale-client-modal"
				role="dialog"
				aria-labelledby="mobysuite-aftersale-client-modalTitle"
				aria-describedby="mobysuite-aftersale-client-modalDescription"
				style="width: 100% !important;"
			>
				<header 
					class="mobysuite-aftersale-client-modal-header"
					id="mobysuite-aftersale-client-modalTitle"
				>
					<slot
						name="header"
					>
						<b-row class="justify-content-md-center">
							<b-col
								cols="12"
								class="text-center"
							>
								<h4></h4>
							</b-col>
						</b-row>
					</slot>
					<button
						type="button"
						class="mobysuite-aftersale-client-btn-close"
						@click="close"
						aria-label="Close mobysuite-aftersale-client-modal"
						style="font-size: 20px !important;"
					>
						x
					</button>
				</header>
				<section 
					class="mobysuite-aftersale-client-modal-body"
					id="mobysuite-aftersale-client-modalDescription"
				>
					<slot
						name="body"
					>
						<b-row
							class="justify-content-md-center"
						>
							<b-col
								sm="12"
								style="text-align: left;"
								v-if="getEnableLessee"
							>
								<mobysuite-aftersale-leaser :customer-name="getCustomer" is-inside-aftersale-widget="true" v-if="getEnableLessee"></mobysuite-aftersale-leaser>
							</b-col>
						</b-row>
					</slot>
				</section>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'LesseeWidget',
	data() {
        return {
        }
    },
	components: {
	},
	mounted() {
	},
	methods: {
		...mapMutations('general', [
			'setShowLesseeWidget',
		]),
		close() {
			this.$emit('close')
			this.setShowLesseeWidget(false)
		},
	},
    props: {
    },
    computed: {
		...mapGetters('general', [
			'getCustomer',
			'getEnableLessee',
        ])
    }
}
</script>
<style scoped>
@media (max-width: 800px) {
  .mobysuite-aftersale-client-modal-body {
    padding: 0;
  }
}
</style>