import Vue from 'vue'
import Vuex from 'vuex'
import general from '@/store/modules/general'
import elements from '@/store/modules/elements'
import assets from '@/store/modules/assets'

Vue.use(Vuex)

const modules = {
    general,
	elements,
	assets,
}

export default new Vuex.Store({
	modules,
})