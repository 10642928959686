import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuth from "@websanova/vue-auth";
import auth from "./auth.js";
import vueCustomElement from 'vue-custom-element'
import Vuelidate from 'vuelidate'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})

Vue.router = router
App.router = Vue.router
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAxios, instance)
Vue.use(VueAuth, auth);
Vue.use(vueCustomElement)
Vue.use(Vuelidate)

App.store = store

Vue.customElement('mobysuite-aftersale-client', App)
