<template>
	<div>
		<b-row>
			<b-col lg="11" sm="12" class="mr-4 mt-2">
				<b-input-group size="sm" class="mb-2">
					<b-input-group-prepend is-text class="search-box-icon">
						<b-icon icon="search"></b-icon>
					</b-input-group-prepend>
					<b-form-input type="search" class="search-box" v-model="search" placeholder="Buscar un detalle:" v-on:input="paginate(perPage, 0)"></b-form-input>
				</b-input-group>
			</b-col>
		</b-row>
		<b-row class="justify-content-md-center">
			<b-col 
				xl="4"
				lg="4"
				md="12"
				sm="12"
				class="my-2 mx-0"
				v-for="item in paginatedItems"
				:key="item.value"
				align-self="end"
			>
				<b-card
					class="text-center card-hover"
					@click="addToList(item)"
				>
					<b-card-text
						class="card-text-size"
					>
						{{item.text}}
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="6" class="my-1">
				<b-pagination
					@change="onPageChanged"
					:total-rows="totalRows"
					:per-page="perPage"
					v-model="currentPage"
					class="my-2 customPagination"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
	name: "Details",
	data() {
		return {
			search: '',
            selected: null,
            selectedElements: [],
            items: this.data.sort(),
            paginatedItems: this.items,
            currentPage: 1,
            perPage: 12,
            totalRows: this.data.length
		};
	},
	props: {
		data: { type: Array, default: null },
	},
	computed: {
		...mapGetters('elements', ['getAddedElements']),
		fiilteredData(){
			let filtered = this.items.filter((item) => {
				return item.text.toLowerCase().match(this.search.toLowerCase())
			})
			return filtered
		},
	},
	methods: {
		...mapMutations('elements', [
			'setAddedElements',
			'setSelectedDetail',
		]),
		...mapMutations('general', ['setLoadingState']),
        ...mapActions('elements', [
            'fetchProblemsData',
        ]),
		addToList(element) {
			this.setLoadingState(true)
			this.fetchProblemsData(element.value)
			let selectedDetail = {
				"value": element.value,
				"text": element.text,
			}
			this.setSelectedDetail(selectedDetail)
			this.selectedElements = this.getAddedElements
			this.selectedElements.push(selectedDetail)
			this.setAddedElements(this.selectedElements)
		},
		paginate(page_size, page_number) {
			let itemsToParse = this.fiilteredData;
			this.paginatedItems = itemsToParse.slice(
				page_number * page_size,
				(page_number + 1) * page_size
			);
			this.totalRows = itemsToParse.length
		},
		onPageChanged(page) {
			this.paginate(this.perPage, page - 1);
		}
	},
	mounted() {
		this.paginate(this.perPage, 0);
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.card-body{
		flex: 1 1 auto !important;
		min-height: 1px !important;
		padding: 1.25rem !important;
	}
</style>
