<template>
	<transition 
		name="mobysuite-aftersale-client-modal-fade"
	>
		<div 
			class="mobysuite-aftersale-client-modal-backdrop"
		>
			<div
				class="mobysuite-aftersale-client-modal"
				role="dialog"
				aria-labelledby="mobysuite-aftersale-client-modalTitle"
				aria-describedby="mobysuite-aftersale-client-modalDescription"
			>
				<header 
					class="mobysuite-aftersale-client-modal-header"
					id="mobysuite-aftersale-client-modalTitle"
				>
					<slot
						name="header"
					>
						<b-row class="justify-content-md-center">
							<b-col
								cols="12"
								class="text-center"
							>
								<h4>Recuperar contraseña</h4>
							</b-col>
						</b-row>
					</slot>
					<button
						type="button"
						class="mobysuite-aftersale-client-btn-close"
						@click="close"
						aria-label="Close mobysuite-aftersale-client-modal"
					>
						x
					</button>
				</header>
				<section 
					class="mobysuite-aftersale-client-modal-body"
					id="mobysuite-aftersale-client-modalDescription"
				>
					<slot
						name="body"
					>
						<b-overlay
							id="overlay-background"
							:show="getSavingState"
							:variant="variant"
							:opacity="opacity"
							:blur="blur"
							rounded="sm"
						>
							
							<b-row
								class="justify-content-md-center"
							>
								<b-col
									sm="12"
									style="text-align: left;"
								>
									<p>Para recuperar su contraseña, por favor, introduzca su rut</p>
								</b-col>
								<b-col
									sm="12"
									class="mb-2"
								>
									<b-form-input
										type="text"
										@keyup="formatRut"
										v-model="rut"
										required
										placeholder="Rut: "
										maxlength="10"
									>
									</b-form-input>
								</b-col>
							</b-row>
							<b-row
								class="justify-content-md-center text-right"
							>
								<b-col
									cols="12"
								>
									<button class="mt-2 mb-3 btn-primary" @click="sendRecoverPassword()"><b-icon icon="mailbox" aria-hidden="true" class="ml-2"></b-icon> Enviar correo</button>
								</b-col>
							</b-row>
						</b-overlay>
						<success-view
							v-if="getShowSuccessView"
							title = "Correo enviado con éxito"
							buttonMessage = "Volver"
							message='Las instrucciones de recuperación de contraseña ha sido enviado con éxito a tu correo: '
							:modalShow="getShowSuccessView"
							successType = "recoverPassword"
						></success-view>
						<error-view
							v-if="getShowErrorView"
							title = "Hubo un error al enviar el correo"
							buttonMessage = "Volver"
							message='El rut que has indicado no ha podido ser encontrado. Inténtalo de nuevo con un rut correcto.'
							:modalShow="getShowErrorView"
							errorType = "recoverPassword"
						>
						</error-view>
					</slot>
				</section>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SuccessView from '@/components/SuccessView.vue'
import ErrorView from '@/components/ErrorView.vue'

export default {
    name: 'RecoverPassword',
	data() {
        return {
			rut: '',
			variant: 'light',
            opacity: 0.85,
            blur: '2px',
        }
    },
	components: {
        SuccessView,
		ErrorView,
	},
	mounted() {
	},
	methods: {
		...mapActions('general', ['recoverPassword']),
		sendRecoverPassword()
		{
			this.recoverPassword({
				rut: this.rut,
				customer_id: this.getCustomerId,
				url: window.location.href,
			})
		},
		close() {
			this.$emit('close')
		},
		digito(rut, dv) {
			let suma = 0;
			let multiplicador = 2;
			while (rut !== 0) {
				if (multiplicador === 8) {
					multiplicador = 2;
				}
				suma += (rut % 10) * multiplicador;
				rut = Math.trunc(rut / 10);
				multiplicador++;
			}
			suma = 11 - (suma % 11);
			switch (suma) {
				case 11:
					return ("0" === dv);
				case 10:
					return ("K" === dv.toUpperCase());
				default:
					return (suma.toString() === dv);
			}
		},
		formatRut(){
			if(this.rut.length > 0){
				this.rut = this.rut.replace(/[^\dKk]/g, '');
				let dato = this.rut;
				let largo = dato.length;
				if (largo === 1) {
					this.dv = dato;
					this.rut = '';
					this.digito(this.rut, this.dv);
					this.formato()
				} else if (largo > 1) {
					this.dv = dato.substring(largo - 1);
					this.rut = dato.substring(largo - 1, 0);
					this.digito(this.rut, this.dv);
					this.formato()
				}
			}
		},
		formato() {
			let rut = (this.rut) ? (parseInt(this.rut)) + '-' : '';
			this.rut = rut + this.dv;
		},
	},
    props: {
    },
    computed: {
		...mapGetters('general', [
            'getSavingState',
            'getShowSuccessView',
			'getShowErrorView',
			'getCustomerId',
        ])
    }
}
</script>
