import Vue from 'vue';
import Router from 'vue-router';
import Main from '../views/Main.vue';

Vue.use(Router);
export default new Router({
	mode: 'history',
	routes: [
		{
			path: '',
			component: Main,
			name: 'main',
			meta: {
				auth: true
			},
			props: true
		},
	]
})
