<template>
	<div>
        <b-navbar toggleable="lg" type="light" style="border-radius: 10px;" class="mx-3 mt-3 bg-white menu-navbar-text text-capitalize">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item class="ml-4 menu-navbar-text-default">
                        <b-icon icon="calendar" aria-hidden="true"></b-icon> {{date}}
                    </b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto mr-4" style="margin-left: auto !important;" v-if="(getContractId == null && getIsInAfterSaleRoom == false)">
                    <b-nav-item-dropdown :text="user.name + ' ' + user.lastname">
                        <b-dropdown-item @click="showProfileModal">Datos personales <b-icon icon="person" aria-hidden="true"></b-icon></b-dropdown-item>
                        <b-dropdown-item @click="logout">Cerrar sesión <b-icon icon="power" aria-hidden="true"></b-icon></b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <div v-if="!getIsAllVitalDataSelected">
            <project-selection
                :projectData="getProjectData" v-if="getProjectData"
            />
        </div>

        <div v-else class="">
            <b-row class="mt-4 mx-3">
                <b-col
                    cols="12"
                >
                    <h1>
                        <b-icon icon="arrow-left" aria-hidden="true" @click="changeProject()" class="button-icon" style="color: black;"></b-icon>
                        Solicitud
                    </h1>
                </b-col>
            </b-row>
            <b-row class="mt-1 mx-3">
                <b-col
                    cols="12"
                >
                    <b-tabs content-class="mt-3 tabs-background">
                        <b-tab title="GENERAR" :active="getMenuOption=='Generate'">
                            <asset-info></asset-info>
                            <create-request></create-request>
                        </b-tab>
                        <b-tab title="HISTORIAL" :active="getMenuOption=='Historical'">
                            <asset-info></asset-info>
                            <historical
                            :activeRequests="getFetchedRequests.active"
                            :finishedRequests="getFetchedRequests.finished"
                            :historicalRequests="getFetchedRequests.historical"
                            :activeRequestElements="getFetchedRequests.active_elements"
                            :finishedRequestElements="getFetchedRequests.finished_elements"
                            :historicalRequestElements="getFetchedRequests.historical_elements"
                            :isActiveEnabled="getFetchedRequests.actives"
                            :isFinishedEnabled="getFetchedRequests.finisheds"
                            :isHistoricalEnabled="getFetchedRequests.historicals"
                        />
                        </b-tab>
                        <b-tab title="AGENDAMIENTOS" :active="getMenuOption=='Schedulings'">
                            <asset-info></asset-info>
                            <schedulings
                                :schedulings="getScheduledDatesByAsset"
                            />
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
        <profile-modal
            v-show="isProfileModalVisible"
            @close="closeModal"
        >
        </profile-modal>
    </div>
</template>

<script>
import ProjectSelection from '@/components/ProjectSelection.vue'
import AssetInfo from '@/components/AssetInfo.vue'
import Historical from '@/components/Historical.vue'
import Schedulings from '@/components/Schedulings.vue'
import CreateRequest from '@/components/CreateRequest.vue'
import ProfileModal from '@/components/ProfileModal.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'Menu',
    data() {
        return {
            isProfileModalVisible: false,
        }
    },
    components: {
        ProjectSelection,
        AssetInfo,
        CreateRequest,
        Historical,
        ProfileModal,
        Schedulings,
    },
    props: {
    },
    methods:  {
        ...mapMutations('assets', ['setIsAllVitalDataSelected']),
        ...mapActions('assets', ['resetAssetsOnLogOut']),
        ...mapActions('elements', ['resetElementsOnLogOut']),
        ...mapActions('general', ['resetGeneralOnLogOut']),
        logout(){
            try {
                this.resetAssetsOnLogOut()
                this.resetElementsOnLogOut()
                this.resetGeneralOnLogOut()
                this.$auth.logout()
            } catch (error) {
                console.log('error')
            }
        },
        changeProject(){
            this.setIsAllVitalDataSelected(false)
        },
        closeModal() {
			this.isProfileModalVisible = false
		},
        showProfileModal(){
            this.isProfileModalVisible = true;
        },
    },
    computed: {
        user(){
            return this.$auth.user();
        },
        date(){
            let date = new Date();
            let event = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return event.toLocaleDateString('es-ES', options);
        },
		...mapGetters('general', [
            'getMenuOption',
            'getContractId',
            'getIsInAfterSaleRoom',
        ]),
        ...mapGetters('assets', [
            'getProjectData',
            'getIsAllVitalDataSelected',
            'getFetchedRequests',
            'getScheduledDatesByAsset',
        ]),
        ...mapGetters('general', [
            'getMenuOption',
        ])
	},
}
</script>

<style>
.button-icon{
  color: #00718C !important;
  font-size: 22px !important;
  cursor: pointer !important;
}
</style>
