<template>
	<b-row class="mx-0">
		<b-col cols="12">
			<b-row
				v-if="(getContractId == null && getIsInAfterSaleRoom == false)"
			>
				<b-col
					cols="12"
					class="mt-5"
					align="center"
				>
					<h1 class="text-capitalize project-client-name" >¡Hola {{user.name}} {{user.lastname}}!</h1>
				</b-col>
			</b-row>

			<b-row
				class="justify-content-md-center text-center"
				v-if="(getContractId == null && getIsInAfterSaleRoom == false)"
			>
				<b-col lg="5" sm="10" class="pb-3">
					<b-input-group size="sm" class="mb-2">
						<b-input-group-prepend is-text class="search-box-icon">
							<b-icon icon="search" class=""></b-icon>
						</b-input-group-prepend>
						<b-form-input type="search" class="search-box" v-model="search" placeholder="Buscar proyectos, contratos o bienes" v-on:input="paginate(perPage, 0)"></b-form-input>
					</b-input-group>
				</b-col>
			</b-row>

			<b-row
				class="justify-content-md-center"
				v-if="(paginatedItems ? paginatedItems.length > 1 : false) || isMobile()"
			>
				<b-col
					xl="6"
					sm="12"
					class="mobysuite-aftersale-client-project-card"
					v-for="(item, key) in paginatedItems"
					:key="`data${key}`"
				>
					<!-- Image holder for cards -->
					<b-row
						class="mobysuite-aftersale-client-project-card-image-holder"
					>
						<b-col
							class="m-2 mobysuite-aftersale-client-project-card-image-background"
						>
							<div class="mobysuite-aftersale-client-project-card-image">
								<img :src="getImageURL(item.project_id)" class="mobysuite-aftersale-client-project-image">
							</div>
						</b-col>
					</b-row>
					<!-- Data holder for cards -->
					<b-row
						class="mobysuite-aftersale-client-project-card-info"
					>
						<div
							class="mobysuite-aftersale-client-contract"
						>
							<p
								class="mobysuite-aftersale-client-contract-info"
							>
								Contrato: {{(new Intl.NumberFormat('es-CL').format(item.contract_id))}}
							</p>
						</div>
						<b-col
							class="m-1"
						>
							<b-row
								class="mt-4"
							>
								<b-col
									cols="8"
								>
									<h1
										class="mobysuite-aftersale-client-project-card-title"
									>
										{{item.project_name}}
									</h1>
								</b-col>
								<b-col
									cols="4"
								>
									<div class="card-image-project-select">
										<img :src="getImageURL(item.project_id)">
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<p class="mobysuite-aftersale-client-project-card-assets">Bienes</p>
								</b-col>
							</b-row>
							<b-row>
								<b-col
									cols="12"
									style="padding-right: 10px; padding-left: 10px;"
								>
									<b-table
										class="data-table"
										hover
										responsive
										:items="item['data']"
										:fields="fields"
										empty-filtered-text="No hay datos que concuerden con tu búsqueda"
									>
										<template #cell(requests)="row">
											<b-row
												class="justify-content-md-center"
											>
												<b-col cols="1">
													<b-icon icon="eye" aria-hidden="true" @click="selectAction(row.item, 'Historical')" class="button-icon" style="color: #00718C !important;"></b-icon>
												</b-col>
												<b-col cols="2">
													<b-icon icon="plus-circle-fill" aria-hidden="true" @click="selectAction(row.item, 'Generate')" class="button-icon" style="color: #00718C !important;"></b-icon>
												</b-col>
											</b-row>
										</template>
									</b-table>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row
				class="justify-content-md-center"
				v-else
			>
				<b-col
					xl="12"
					sm="12"
					class="mobysuite-aftersale-client-project-one-card"
					v-for="(item, key) in paginatedItems"
					:key="`data${key}`"
				>
					<b-row>
						<!-- Image holder for cards -->
						<b-col
							cols="3"
							class=""
						>
							<b-row>
								<b-col
									class="mobysuite-aftersale-client-project-one-card-image-background"
								>
									<div class="mobysuite-aftersale-client-project-card-image">
										<img :src="getImageURL(item.project_id)" class="mobysuite-aftersale-client-project-image">
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col
									cols="12"
								>
									<b-row
										class="mt-4"
									>
										<b-col
											cols="12"
										>
											<h1
												class="mobysuite-aftersale-client-project-one-card-title text-center mb-4"
											>
												{{item.project_name}}
											</h1>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row>
								<b-col
									cols="12"
								>
									<div
										class="mobysuite-aftersale-client-one-contract"
									>
										<p
											class="mobysuite-aftersale-client-one-contract-info"
										>
											Contrato: {{(new Intl.NumberFormat('es-CL').format(item.contract_id))}}
										</p>
									</div>
								</b-col>
							</b-row>
						</b-col>
						<!-- Data holder for cards -->
						<b-col
							cols="9"
							class="mobysuite-aftersale-client-project-one-card-info"
						>
							<b-col
								class="mt-3"
							>
								<b-row>
									<b-col>
										<p class="mobysuite-aftersale-client-project-card-assets">Bienes</p>
									</b-col>
								</b-row>
								<b-row>
									<b-col
										cols="12"
										style="padding-right: 10px; padding-left: 10px;"
									>
										<b-table
											class="one-data-table"
											hover
											responsive
											:items="item['data']"
											:fields="fields"
											empty-filtered-text="No hay datos que concuerden con tu búsqueda"
										>
											<template #cell(requests)="row">
												<b-row
													class="justify-content-md-center"
												>
													<b-col cols="1">
														<b-icon icon="eye" aria-hidden="true" @click="selectAction(row.item, 'Historical')" class="button-icon" style="color: #00718C !important;"></b-icon>
													</b-col>
													<b-col cols="2">
														<b-icon icon="plus-circle-fill" aria-hidden="true" @click="selectAction(row.item, 'Generate')" class="button-icon" style="color: #00718C !important;"></b-icon>
													</b-col>
												</b-row>
											</template>
										</b-table>
									</b-col>
								</b-row>
							</b-col>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'ProjectSelection',
	data() {
		return {
			search: '',
			items: this.projectData,
			paginatedItems: this.items,
			perPage: 12,
			totalRows: this.projectData.length,
			fields: [
				{
					key: 'asset_number',
					label: 'N°',
					formatter: (value, key, item) => {
						return  item.asset_number ? item.asset_number : 'Sin N°'
					},
				},
				{
					key: 'asset_type',
					label: 'Tipo',
					formatter: (value, key, item) => {
						return  item.asset_type ? item.asset_type : 'Sin tipo'
					},
				},
				{
					key: 'requests',
					label: 'Solicitudes',
				},
			],
        }
    },
	props: {
		projectData: { type: Array, default: null },
    },
	created() {
	},
	mounted() {
		this.paginate(this.perPage, 0)
		this.getYearlyHollidaysChile()
	},
	computed: {
		...mapGetters('assets', [
			'getImageURL',
		]),
		...mapGetters('general', [
			'getContractId',
			'getIsInAfterSaleRoom',
		]),
		fiilteredData(){
			let filtered = this.items.filter((item) => {
				return item.project_name.toLowerCase().match(this.search.toLowerCase()) ||
						item.asset_number.toString().match(this.search.toLowerCase()) ||
						item.contract_id.toString().match(this.search.toLowerCase()) ||
						item.asset_type.toLowerCase().match(this.search.toLowerCase())
			})
			return filtered
		},
		user(){
			return this.$auth.user()
		}
	},
  watch: {
    projectData: {
      handler: function () {
        if(this.projectData){
          this.items = this.projectData;
          this.paginatedItems = this.items;
          this.totalRows = this.projectData.length;
        }
      },
      immediate: true
    },
  },
	methods: {
		...mapMutations('assets', [
			'setSelectedProject',
			'setSelectedAsset',
			'setIsAllVitalDataSelected',
		]),
		...mapMutations('general', ['setMenuOption']),
		...mapActions('assets', [
			'getRequestsByAsset',
			'getAllScheduledDatesByAsset',
		]),
		...mapActions('general', [
			'getDaysAndHoursToReSchedule',
			'getYearlyHollidaysChile',
		]),
		selectAction(item, menuOption)
		{
			let projectInfo =
				{
					project_name: item.project_name,
					project_id: item.project_id
				}
			let asset =
				{
					asset_id: item.asset_id,
					asset_type: item.asset_type,
					asset_number: item.asset_number
				}
			let project = 
				{
					project_id: item.project_id,
					project_name: item.project_name
				}
			this.setSelectedProject(projectInfo)
			this.setSelectedAsset(item)
			this.getRequestsByAsset({asset: asset, project: project})
			this.getAllScheduledDatesByAsset({asset_id: item.asset_id})
			this.getDaysAndHoursToReSchedule()
			this.setMenuOption(menuOption)
		},
		paginate(page_size, page_number) {
			let itemsToParse = this.fiilteredData;
			this.paginatedItems = itemsToParse.slice(
				page_number * page_size,
				(page_number + 1) * page_size
			)
		},
		isMobile(){
			if (/Mobi|Android/i.test(navigator.userAgent)) {
				return true
			}
			else{
				return false
			}
		}
	},
}
</script>

<style scoped>
.button-icon{
  color: #00718C !important;
  font-size: 22px !important;
  cursor: pointer !important;
}
.data-table {
  padding: 0px;
  background-color: #F5F5F5;
  text-align: center;
  border-radius: 10px;
  max-height: 185px;
  overflow-y: overlay;
  overflow-x: hidden;
}
.data-table::-webkit-scrollbar {
  width: 3px;
  height: 4px !important;
}
.data-table::-webkit-scrollbar-thumb {
  background-color: #00718C;
  border-radius: 30rem;
}
.data-table::-webkit-scrollbar-track {
  right: -3rem;
  top: -50rem;
  background: #c4c4c4;
}

.one-data-table {
  padding: 0px;
  background-color: #F5F5F5;
  text-align: center;
  border-radius: 10px;
  max-height: 180px;
  overflow-y: overlay;
  overflow-x: hidden;
}

.one-data-table::-webkit-scrollbar {
  width: 3px;
  height: 4px !important;
}
.one-data-table::-webkit-scrollbar-thumb {
  background-color: #00718C;
  border-radius: 30rem;
}
.one-data-table::-webkit-scrollbar-track {
  right: -3rem;
  top: -50rem;
  background: #c4c4c4;
}

.mobysuite-aftersale-client-project-card{
  background-color: #E5EBEC;
  height: fit-content;
  max-width: 350px;
  margin: 70px;
  border-radius: 0px 0px 17.4279px 17.4279px;
  box-shadow: 0px 4.64743px 4.64743px rgba(0, 0, 0, 0.25);
  z-index: 1015;
}

.mobysuite-aftersale-client-project-one-card{
  background-color: #E5EBEC;
  height: 305px;
  max-width: 70%;
  margin: auto;
  margin-bottom: 10%;
  border-radius: 0px 0px 17.4279px 17.4279px;
  box-shadow: 0px 4.64743px 4.64743px rgba(0, 0, 0, 0.25);
  z-index: 1015;
}

@media only screen and (max-width: 800px) {
	.mobysuite-aftersale-client-project-card{
		max-width: 330px;
		margin: auto;
		margin-bottom: 25px;
		margin-left: 10px;
	}
}

@media only screen and (max-width: 400px) {
	.mobysuite-aftersale-client-project-card{
		max-width: 300px;
		margin: auto;
		margin-bottom: 30px;
	}
	.data-table {
		overflow-x: visible !important;
	}
	.mobysuite-aftersale-client-project-card-info{
		width: 111% !important;
		display: flex !important;
	}
}
@media only screen and (min-width: 400px)  and (max-width: 500px) {
	.mobysuite-aftersale-client-project-card{
		max-width: 380px;
		margin: auto;
		margin-bottom: 30px;
	}
	.data-table {
		overflow-x: visible !important;
	}
	.mobysuite-aftersale-client-project-card-info{
		display: flex !important;
	}
}
@media only screen and (min-width: 501px)  and (max-width: 720px) {
	.mobysuite-aftersale-client-project-card{
		max-width: 360px;
		margin: auto;
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 721px)  and (max-width: 799px) {
	.mobysuite-aftersale-client-project-card{
		max-width: 330px;
		margin: auto;
		margin-bottom: 25px;
	}
}
.mobysuite-aftersale-client-project-card-image-holder{
	height: 225px;
	position: relative;
}

.mobysuite-aftersale-client-project-card-one-image-holder{
	height: 82px;
	position: relative;
}

.mobysuite-aftersale-client-project-card-image-background{
	height: 150px;
	max-width: 90%;
	background: white;
	margin: 0;
	position: absolute;
	top: 30%;
	left: 30%;
	transform: translate(-30%, -30%);
}


.mobysuite-aftersale-client-project-one-card-image-background{
	height: 175px;
	max-width: 90%;
	background: white;
	margin: 0;
	position: relative;
	top: 72px;
	left: 32%;
	transform: translate(-30%, -30%);
	margin-bottom: 10px;
}

.mobysuite-aftersale-client-project-card-image{
	display: flex;
	justify-content: center;
	max-height: 150px;
	height: 100%;
	max-width: 98%;
}

.mobysuite-aftersale-client-project-image{
	max-width: 100%;
}

.mobysuite-aftersale-client-contract{
	position: absolute;
	background-color:  #00718C;;
	width: 55%;
	height: 30px;
	border-radius: 35px;
	top: -15px;
	left: 10px;
}

.mobysuite-aftersale-client-one-contract{
	position: relative;
	background-color:  #00718C;;
	width: 100%;
	height: 30px;
	border-radius: 35px;
	margin-top: 6%;
}

.mobysuite-aftersale-client-contract-info{
	color: white;
	display: flex;
	font-size: 1.35rem;
	font-weight: 600;
	line-height: 20px;
	justify-content: center;
	align-items: center;
	height: 30px;
}

.mobysuite-aftersale-client-one-contract-info{
	color: white;
	display: flex;
	font-size: 1rem;
	font-weight: 600;
	line-height: 20px;
	justify-content: center;
	align-items: center;
	height: 30px;
}

.mobysuite-aftersale-client-project-card-info{
	background-color: white;
	border-radius: 0px 0px 17.4279px 17.4279px;
	width: 109.5%;
}

.mobysuite-aftersale-client-project-one-card-info{
	margin: auto;
	margin-top: 20px;
	height: 265px;
	max-width: 72%;
	background-color: white;
	border-radius: 17.4279px 17.4279px 17.4279px 17.4279px;
}

.mobysuite-aftersale-client-project-card-title{
	font-weight: 500;
	font-size: 1rem;
	max-height: 10px;
	line-height: 18px;
}
.mobysuite-aftersale-client-project-one-card-title{
	font-weight: 500;
	font-size: 0.9em;
	max-height: 10px;
	line-height: 18px;
}
.mobysuite-aftersale-client-project-card-assets{
	font-weight: 600;
	font-size: 20px;
}
.card-image-project-select {
	display: flex;
}

div.card-image-project-select img {
	max-width: 66px;
	max-height: 40px;
	width: auto;
	height: auto;
}


@media only screen and (max-width: 37.5em) {
	div.card-image-project-select img {
		width: 100%;
		height: auto;
	}

	.card-image-project-select {
		flex-direction: column;
	}

	.card-project-select {
		width: 100%;
		margin-top: -4px;
	}
}
</style>
