<template>
    <div>
        <div class="request-container mt-4 mb-5">
            <b-row>
                <b-col
                    lg="4"
                    sm="12"
                >
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <h5>Requerimientos</h5>
                        </b-col>
                    </b-row>
                    <b-row
                        class=" mb-3"
                        align-v="center"
                    >
                        <b-col
                            lg="3"
                            sm="12"
                        >
                            <p :class="getProcessStage == 'places' ? 'create-requests-stepper-active' : 'create-requests-stepper'">Lugar: </p>
                        </b-col>
                        <b-col
                            lg="9"
                            sm="12"
                            v-if="getAddedElements[0]"
                        >
                            <b-card
                                class="text-center bg-light"
                                align="center"
                                border-variant="light"
                            >
                                <b-card-text>
                                    <b-row class="text-right" style="height: 6px;">
                                        <b-col cols="12">
                                            <b-icon icon="x-circle" variant="danger" :class="getCustomerId == 71 ? 'close-button-iandes' : 'close-button'" @click="stepperReturnPlaces" style="color: #dc3545 !important;"></b-icon>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            {{getAddedElements[0].text}}
                                        </b-col>
                                    </b-row>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row
                        class="mb-3"
                        align-v="center"
                    >
                        <b-col
                            lg="3"
                            sm="12"
                        >
                            <p :class="getProcessStage == 'items' ? 'create-requests-stepper-active' : 'create-requests-stepper'">Item: </p>
                        </b-col>
                        <b-col
                            lg="9"
                            sm="12"
                            v-if="getAddedElements[1]"
                        >
                            <b-card
                                class="text-center bg-light"
                                align="center"
                                border-variant="light"
                            >
                                <b-card-text>
                                    <b-row class="text-right pb-0" style="height: 6px;">
                                        <b-col cols="12">
                                             <b-icon icon="x-circle" variant="danger" :class="getCustomerId == 71 ? 'close-button-iandes' : 'close-button'" @click="stepperReturnItems" style="color: #dc3545 !important;"></b-icon>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            {{getAddedElements[1].text}}
                                        </b-col>
                                    </b-row>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row
                        class="mb-3"
                        align-v="center"
                    >
                        <b-col
                            lg="3"
                            sm="12"
                        >
                            <p :class="getProcessStage == 'details' ? 'create-requests-stepper-active' : 'create-requests-stepper'">Detalle: </p>
                        </b-col>
                        <b-col
                            lg="9"
                            sm="12"
                            v-if="getAddedElements[2]"
                        >
                            <b-card
                                class="text-center bg-light"
                                align="center"
                                border-variant="light"
                            >
                                <b-card-text>
                                    <b-row class="text-right" style="height: 6px;">
                                        <b-col cols="12">
                                            <b-icon icon="x-circle" variant="danger" :class="getCustomerId == 71 ? 'close-button-iandes' : 'close-button'" @click="stepperReturnDetails" style="color: #dc3545 !important;"></b-icon>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            {{getAddedElements[2].text}}
                                        </b-col>
                                    </b-row>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row
                        class="mb-3"
                        align-v="center"
                    >
                        <b-col
                            lg="3"
                            sm="12"
                        >
                            <p :class="getProcessStage == 'problems' ? 'create-requests-stepper-active' : 'create-requests-stepper'">Problema: </p>
                        </b-col>
                        <b-col
                            lg="9"
                            sm="12"
                            v-if="getAddedElements[3]"
                        >
                            <b-card
                                class="text-center bg-light"
                                align="center"
                                border-variant="light"
                            >
                                <b-card-text>
                                    <b-row class="text-right" style="height: 6px;">
                                        <b-col cols="12">
                                            <b-icon icon="x-circle" variant="danger" :class="getCustomerId == 71 ? 'close-button-iandes' : 'close-button'" @click="stepperReturnProblems" style="color: #dc3545 !important;"></b-icon>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            {{getAddedElements[3].text}}
                                        </b-col>
                                    </b-row>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                    <observations
                        :max="4"
                        maxError="Solo puedes subir 4 archivos por requerimiento."
                        fileError="Solo se aceptan archivos PNG, JPEG"
                        clearAll="Limpiar todos los archivos"
                    />
                </b-col>
                <b-col
                    lg="8"
                    sm="12"
                >
                    <b-overlay
                        id="overlay-background"
                        :show="getLoadingState"
                        :variant="variant"
                        :opacity="opacity"
                        :blur="blur"
                        rounded="sm"
                    >
                        <places
                            :data="getFetchedPlaces"
                            v-if="getProcessStage == 'places' && getFetchedPlaces.length"
                        />
                        <items
                            :data="getFetchedItems"
                            v-if="getProcessStage == 'items' && getFetchedItems"
                        />
                        <Details
                            :data="getFetchedDetails"
                            v-if="getProcessStage == 'details' && getFetchedDetails"
                        />
                        <problems
                            :data="getFetchedProblems"
                            v-if="getProcessStage == 'problems' && getFetchedProblems"
                        />
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
        <div class="request-container mb-4" id="added-requirements" :hidden="getAddedRequirements.length == 0 ">
            <b-overlay
                id="overlay-background"
                :show="getSavingState"
                :variant="variant"
                :opacity="opacity"
                :blur="blur"
            >
                <b-row
                    class="justify-content-md-center text-center mb-3" 
                    align-v="center"
                >
                    <h1>Requerimientos añadidos</h1>
                </b-row>
                <b-row class="my-4 justify-content-md-center">
                    <b-col
                        cols="11"
                        class="mx-2"
                    >
                        <b-table
                            hover
                            bordered
                            responsive
                            head-variant="light"
                            :items="getAddedRequirements"
                            :fields="fields"
                            class="data-table mb-4"
                        >
                            <template #cell(description)="row">
                                <b-row class="wordwrap">
                                    <b-col v-if="!readMore[row.index]">
                                        <div v-if="!(row.item.description === null)">
                                            <p v-if="row.item.description.length > 10">
                                                {{row.item.description.slice(0, 9)}}... <b-link class="font-weight-bold" @click="showMore(row.index)" style="color: black;">Ver más</b-link>
                                            </p>
                                            <p v-else>
                                                {{row.item.description}}
                                            </p>
                                        </div>
                                    </b-col>
                                    <b-col v-if="readMore[row.index]">
                                        {{row.item.description}} <b-link class="font-weight-bold" href="" @click="showLess(row.index)" style="color: black;">Ver menos</b-link>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #cell(files)="row">
                                <b-row align="center">
                                    <b-col cols="12">
                                        <b-button variant="secondary" class="mx-0" v-if="row.item.request_element_images.length > 0" @click="showImagesModal(row.item.request_element_images, row.index)">
                                            <b-icon icon="file-earmark-fill" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #cell(delete)="row">
                                <b-row align="center">
                                    <b-col cols="12">
                                        <b-button variant="danger" class="mx-0" @click="showDeleteRowModal(row.index)">
                                            <b-icon icon="trash" aria-hidden="true" ></b-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #row-details="row">
                                <b-card>
                                    <ul>
                                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                    </ul>
                                        {{row.index}}
                                </b-card>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row class="justify-content-md-center mr-2">
                    <b-col
                        lg="6"
                        sm="6"
                        align="left"
                    >
                    </b-col>
                    <b-col
                        lg="6"
                        sm="6"
                        align="right"
                    >
                        <button type="submit" variant="dark" class="mb-4 mr-5 px-5 btn-primary save-btn" @click="createRequest">Enviar<b-icon icon="download" aria-hidden="true" class="ml-2"></b-icon></button>
                    </b-col>
                </b-row>
            </b-overlay>
            <success-view
                v-if="getShowSuccessView"
                title = "Éxito al crear tu solicitud"
                buttonMessage = "Volver a la página principal"
                :message="createSuccessMessage()"
                :modalShow="getShowSuccessView"
                successType = "request"
                :hasAutomaticScheduling = "getHasAutomaticScheduling"
            ></success-view>
            <error-view
                v-if="getShowErrorView"
                title = "Error al procesar la solicitud"
                buttonMessage = "Volver"
                message='Hubo un error al tratar de guardar tu solicitud. Por favor, intenta de nuevo más tarde.'
                :modalShow="getShowErrorView"
                errorType = "request"
            >
            </error-view>
            <images-modal
                v-show="isImagesModalVisible"
                @close="closeModal"
                v-bind="modalImagesData"
            >
            </images-modal>
            <delete-row-modal
                v-show="isDeleteRowModalVisible"
                @close="closeModal"
                v-bind="modalDeleteRowData"
            >
            </delete-row-modal>
        </div>
        <automatic-scheduling
            v-show="getShowAutomaticScheduling"
            scheduleTitle="agendamiento"
            type="request"
        >
        </automatic-scheduling>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, } from 'vuex'
import Places from '@/components/Places.vue'
import Items from '@/components/Items.vue'
import Details from '@/components/Details.vue'
import Problems from '@/components/Problems.vue'
import Observations from '@/components/Observations.vue'
import SuccessView from '@/components/SuccessView.vue'
import ErrorView from '@/components/ErrorView.vue'
import ImagesModal from '@/components/ImagesModal.vue'
import DeleteRowModal from '@/components/DeleteRowModal.vue'
import AutomaticScheduling from '@/components/AutomaticScheduling.vue'

export default {
    name: 'CreateRequest',
	data() {
		return {
            rowImages: null,
            rowIndex: null,
            isImagesModalVisible: false,
            isDeleteRowModalVisible: false,
            variant: 'light',
            opacity: 0.85,
            blur: '2px',
            readMore: {},
            fields: [
                {
                    key: 'place',
                    label: 'Lugar',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                },
                {
                    key: 'item',
                    label: 'Problema',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                    
                },
                {
                    key: 'detail',
                    label: 'Item',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                },
                {
                    key: 'problem',
                    label: 'Detalle Item',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                },
                {
                    key: 'description',
                    label: 'Descripción',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                    thStyle: { width: '17%'},
                },
                {
                    key: 'files',
                    label: 'Archivos',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                },
                {
                    key: 'delete',
                    label: 'Borrar',
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle',
                },
			],
		}
	},
    created() {
    },
	components: {
        Places,
        Items,
        Details,
        Problems,
        Observations,
        SuccessView,
        ErrorView,
        ImagesModal,
        DeleteRowModal,
        AutomaticScheduling,
	},
	methods: {
		...mapActions('general', ['createRequest']),
		...mapMutations('general', ['setMenuOption']),
        ...mapMutations('elements', [
            'setSelectedPlace',
            'setSelectedItem',
            'setSelectedDetail',
            'setSelectedProblem',
            'setProcessStage',
            'setAddedElements',
            'setAddedRequirements',
        ]),
        reset() {
            this.setSelectedPlace(null)
            this.setSelectedItem(null)
            this.setSelectedDetail(null)
            this.setSelectedProblem(null)
            this.setProcessStage('places')
            this.setAddedElements([])
        },
        showMore(id) {
            this.$set(this.readMore, id, true);
        },
        showLess(id) {
            this.$set(this.readMore, id, false);
        },
        createSuccessMessage(){
            let message = "Hemos creado exitosamente tu solicitud con el folio " + this.getRequestResponse + ". \nTe hemos enviado un email con el detalle de tu orden."
            return message
        },
        showImagesModal(item, index) {
            this.rowImages = item;
            this.rowIndex = index;
            this.isImagesModalVisible = true;
		},
        showDeleteRowModal(index){
            this.rowIndex = index;
            this.isDeleteRowModalVisible = true;
        },
		closeModal() {
			this.isImagesModalVisible = false;
            this.isDeleteRowModalVisible = false;
		},
		stepperReturnPlaces(){
            this.setSelectedPlace(null)
            this.setSelectedItem(null)
            this.setSelectedDetail(null)
            this.setSelectedProblem(null)
            this.setProcessStage('places')
            this.setAddedElements([])
        },
        stepperReturnItems(){
            this.setSelectedItem(null)
            this.setSelectedDetail(null)
            this.setSelectedProblem(null)
            this.setProcessStage('items')
            this.setAddedElements(this.getAddedElements.slice(0,1))
        },
        stepperReturnDetails(){
            this.setSelectedDetail(null)
            this.setSelectedProblem(null)
            this.setProcessStage('details')
            this.setAddedElements(this.getAddedElements.slice(0,2))
        },
        stepperReturnProblems(){
            this.setSelectedProblem(null)
            this.setProcessStage('problems')
            this.setAddedElements(this.getAddedElements.slice(0,3))
        },
	},
	computed: {
		...mapGetters('elements', [ 
            'getFetchedPlaces', 
            'getFetchedItems',
            'getFetchedDetails',
            'getFetchedProblems',
            'getProcessStage',
            'getAddedElements',
            'getAddedRequirements',
        ]),
        ...mapGetters('general', [
            'getCustomerId',
            'getLoadingState',
            'getSavingState',
            'getShowSuccessView',
            'getShowErrorView',
            'getRequestResponse',
            'getHasAutomaticScheduling',
            'getShowAutomaticScheduling',
        ]),
        modalImagesData(){
            return {
                images: this.rowImages,
                rowIndex: this.rowIndex,
            }
        },
        modalDeleteRowData(){
            return {
                images: this.rowImages,
                index: this.rowIndex,
            }
        },
	},
}
</script>

<style scoped>
.save-btn{
    width: 50%;
}
@media (max-width: 800px) {
    .save-btn{
    width: 100%;
}
}
.card-body{
    flex: 1 1 auto !important;
    min-height: 1px !important;
    padding: 1.25rem !important;
}

</style>
