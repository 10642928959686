<template>
  <transition 
    name="mobysuite-aftersale-client-modal-fade"
  >
    <div 
      class="mobysuite-aftersale-client-modal-backdrop"
    >
      <div
        class="mobysuite-aftersale-client-modal"
        role="dialog"
        aria-labelledby="mobysuite-aftersale-client-modalTitle"
        aria-describedby="mobysuite-aftersale-client-modalDescription"
      >
        <header 
          class="mobysuite-aftersale-client-modal-header"
          id="mobysuite-aftersale-client-modalTitle"
        >
          <button
            type="button"
            class="mobysuite-aftersale-client-btn-close"
            @click="close"
            aria-label="Close mobysuite-aftersale-client-modal"
          >
            x
          </button>
        </header>

        <section 
          class="mobysuite-aftersale-client-modal-body"
          id="mobysuite-aftersale-client-modalDescription"
        >
          <slot
            name="body"
          >
            <b-row class="justify-content-md-center">
              <b-col cols="12" style="text-align: center;">
                <b-icon icon="exclamation-circle" class="success-view-icon-style" ></b-icon>
                <p>
                  {{getAddedRequirements.length > 1 ? '¿Estás seguro de querer eliminar el requerimiento?' : 'Este es tu único requerimiento, ¿estás seguro de querer eliminar el requerimiento? Serás redireccionado a la selección de lugares.'}}
                </p>
              </b-col>
            </b-row>
          </slot>
        </section>

        <footer 
          class="mobysuite-aftersale-client-modal-footer"
        >
          <slot
            name="footer"
          >
          </slot>
          <b-row
            class="justify-content-md-center text-center"
          >
            <b-col
              lg="6"
              sm="12"
              class="my-2"
            >
              <button
                type="button"
                class="btn-secondary"
                @click="close"
                aria-label="Close mobysuite-aftersale-client-modal"
              >
                Cancelar
              </button>
            </b-col>
            <b-col
              lg="6"
              sm="12"
              class="my-2"
            >
              <button
                type="button"
                class="btn-primary"
                @click="deleteFromAdded"
                aria-label="Close mobysuite-aftersale-client-modal"
              >
                Eliminar
              </button>
            </b-col>
          </b-row>
        </footer>
      </div>
    </div>
  </transition>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'DeleteRowModal',
	methods: {
    ...mapMutations('elements', [
      'setSelectedPlace',
      'setSelectedItem',
      'setSelectedDetail',
      'setSelectedProblem',
      'setProcessStage',
      'setAddedElements',
      'setAddedRequirements',
    ]),
		deleteFromAdded() {
      this.close()
      let addedElements = this.getAddedRequirements
      addedElements.splice(this.index, 1)
      this.setAddedRequirements(addedElements)
      if(this.getAddedRequirements.length == 0){
        this.reset()
      }
    },
    close() {
      this.$emit('close');
    },
    reset() {
      this.setSelectedPlace(null)
      this.setSelectedItem(null)
      this.setSelectedDetail(null)
      this.setSelectedProblem(null)
      this.setProcessStage('places')
      this.setAddedElements([])
      this.close()
    },
  },
  props: {
    modalTitle: { type: String, default: "Eliminar requerimiento" },
    index: { type: Number, default: null },
		images: { type: Array, default: null },
		rowIndex: { type: Number, default: null },
	},
	computed: {
		...mapGetters('elements', [ 
      'getAddedElements',
      'getAddedRequirements',
    ]),
  },
}
</script>

<style scoped>
.success-view-icon-style{
    transform: scale(8);
    margin-top: 40px;
    margin-bottom: 70px;
    color: #00718C;
  }

</style>