<template>
	<div class="mt-4 mb-5 pr-4 request-container">
		<h1>Historial solicitudes</h1>
		<b-tabs pills content-class="mt-3" class="requests-tabs"  v-if="isActiveEnabled || isFinishedEnabled || isHistoricalEnabled">
			<b-tab title="Activas" v-if="isActiveEnabled">
				<b-row>
					<b-col cols="12" class="my-1" v-if="activeRequests.length > 0">
						<b-form-group
							label="Buscar: "
							label-for="filter-input"
							label-cols-sm="7"
							label-align-sm="right"
							label-size="sm"
							class="mb-3"
						>
							<b-input-group size="sm">
								<b-form-input
									id="filter-input"
									v-model="filter"
									type="search"
									placeholder="Escribe para buscar: "
								></b-form-input>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="12" v-if="activeRequests.length > 0">
						<b-table
							class="data-table data-table-request"
							striped
							hover
							bordered
							responsive
							show-empty
							:items="activeRequests"
							:fields="fields"
							:per-page="perPage"
							:current-page="currentPageActive"
							:filter="filter"
							:filter-included-fields="filterOn"
							@filtered="onFiltered(activeRequests)"
							empty-filtered-text="No hay datos que concuerden con tu búsqueda"
						>
							<template #cell(requests)="row">
                                <b-row
									class="justify-content-md-center text-center wordwrap"
								>
                                    <b-col
										sm="3"
										style="text-align: left;"
									>
										<p>{{row["item"].folio === null ? 'Sin folio' : row["item"].folio }} ({{row["item"].req}})</p>
										<p>{{row["item"].request_date === null ? 'Sin fecha' : 'Fecha de solicitud: ' + formatDate(row["item"].request_date) }}</p>
										<p>{{row["item"].created_at === null ? 'Sin fecha' : 'Fecha de creacion: ' + formatDate(row["item"].created_at) }}</p>
									</b-col>
									<b-col
										sm="9"
									>
										<b-table
											class="data-table"
											striped
											hover
											bordered
											responsive
											show-empty
											:items="Object.values(getFetchedRequestsElementsById(row['item'].id, 'active_elements'))"
											:fields="insideFields"
											empty-filtered-text="No hay datos que concuerden con tu búsqueda"
										>
											<template #cell(4)="rowInside">
												<b-row class="wordwrap">
													<b-col v-if="!readMore[row['item'].folio + ' ' + rowInside.index]">
														<div v-if="!(rowInside.item[4] === null)">
															<p v-if="rowInside.item[4].length > 10">
																{{rowInside.item[4].slice(0, 9)}}... <b-link class="font-weight-bold" @click="showMore(row['item'].folio + ' ' + rowInside.index)" style="color: black;">Ver más</b-link>
															</p>
															<p v-else>
																{{rowInside.item[4]}}
															</p>
														</div>
													</b-col>
													<b-col v-if="readMore[row['item'].folio + ' ' + rowInside.index]">
														{{rowInside.item[4]}} <b-link class="font-weight-bold" href="" @click="showLess(row['item'].folio + ' ' + rowInside.index)" style="color: black;">Ver menos</b-link>
													</b-col>
												</b-row>
											</template>
										</b-table>
									</b-col>
                                </b-row>
                            </template>
						</b-table>
						<b-pagination
							v-model="currentPageActive"
							:total-rows="onFiltered(activeRequests)"
							:per-page="perPage"
							aria-controls="my-table"
							class="mt-4 customPaginationHistorical"
						>
						</b-pagination>
					</b-col>
					<b-col v-else>
						<p class="text-center">Por el momento no hay datos disponibles</p>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab title="Terminadas" v-if="isFinishedEnabled">
				<b-row>
					<b-col cols="12" class="my-1" v-if="finishedRequests.length > 0">
						<b-form-group
							label="Buscar: "
							label-for="filter-input"
							label-cols-sm="7"
							label-align-sm="right"
							label-size="sm"
							class="mb-3"
						>
							<b-input-group size="sm">
								<b-form-input
									id="filter-input"
									v-model="filter"
									type="search"
									placeholder="Escribe para buscar: "
								></b-form-input>

							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="12" v-if="finishedRequests.length > 0">
						<b-table
							class="data-table data-table-request"
							striped
							hover
							bordered
							responsive
							show-empty
							:items="finishedRequests"
							:fields="fields"
							:per-page="perPage"
							:current-page="currentPageFinished"
							:filter="filter"
							:filter-included-fields="filterOn"
							@filtered="onFiltered(activeRequests)"
							empty-filtered-text="No hay datos que concuerden con tu búsqueda"
						>
							<template #cell(requests)="row">
                                <b-row
									class="justify-content-md-center text-center wordwrap"
								>
                                    <b-col
										sm="3"
										style="text-align: left;"
									>
										<p>{{row["item"].folio === null ? 'Sin folio' : row["item"].folio }} ({{row["item"].req}})</p>
										<p>{{row["item"].request_date === null ? 'Sin fecha' : 'Fecha de solicitud: ' + formatDate(row["item"].request_date) }}</p>
										<p>{{row["item"].created_at === null ? 'Sin fecha' : 'Fecha de creacion: ' + formatDate(row["item"].created_at) }}</p>
									</b-col>
									<b-col
										sm="9"
									>
										<b-table
											class="data-table"
											striped
											hover
											bordered
											responsive
											show-empty
											:items="Object.values(getFetchedRequestsElementsById(row['item'].id, 'finished_elements'))"
											:fields="insideFields"
											empty-filtered-text="No hay datos que concuerden con tu búsqueda"
										>
											<template #cell(4)="rowInside">
												<b-row class="wordwrap">
													<b-col v-if="!readMore[row['item'].folio + ' ' + rowInside.index]">
														<div v-if="!(rowInside.item[4] === null)">
															<p v-if="rowInside.item[4].length > 10">
																{{rowInside.item[4].slice(0, 9)}}... <b-link class="font-weight-bold" @click="showMore(row['item'].folio + ' ' + rowInside.index)" style="color: black;">Ver más</b-link>
															</p>
															<p v-else>
																{{rowInside.item[4]}}
															</p>
														</div>
													</b-col>
													<b-col v-if="readMore[row['item'].folio + ' ' + rowInside.index]">
														{{rowInside.item[4]}} <b-link class="font-weight-bold" href="" @click="showLess(row['item'].folio + ' ' + rowInside.index)" style="color: black;">Ver menos</b-link>
													</b-col>
												</b-row>
											</template>
										</b-table>
									</b-col>
                                </b-row>
                            </template>
						</b-table>
						<b-pagination
							v-model="currentPageFinished"
							:total-rows="rows(finishedRequests)"
							:per-page="perPage"
							aria-controls="my-table"
							class="mt-4 customPaginationHistorical"
						>
						</b-pagination>
					</b-col>
					<b-col v-else>
						<p class="text-center">Por el momento no hay datos disponibles</p>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab title="Históricas" v-if="isHistoricalEnabled">
				<b-row>
					<b-col cols="12" class="my-1" v-if="historicalRequests.length > 0">
						<b-form-group
							label="Buscar: "
							label-for="filter-input"
							label-cols-sm="7"
							label-align-sm="right"
							label-size="sm"
							class="mb-3"
						>
							<b-input-group size="sm">
								<b-form-input
									id="filter-input"
									v-model="filter"
									type="search"
									placeholder="Escribe para buscar: "
								></b-form-input>

							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="12" v-if="historicalRequests.length > 0">
						<b-table
							class="data-table data-table-request"
							striped
							hover
							bordered
							responsive
							show-empty
							:items="historicalRequests"
							:fields="fields"
							:per-page="perPage"
							:current-page="currentPageHistorical"
							:filter="filter"
							:filter-included-fields="filterOn"
							@filtered="onFiltered(activeRequests)"
							empty-filtered-text="No hay datos que concuerden con tu búsqueda"
						>
							<template #cell(requests)="row">
                                <b-row
									class="justify-content-md-center text-center wordwrap"
								>
                                    <b-col
										sm="3"
										style="text-align: left;"
									>
										<p>{{row["item"].folio === null ? 'Sin folio' : row["item"].folio }} ({{row["item"].req}})</p>
										<p>{{row["item"].request_date === null ? 'Sin fecha' : 'Fecha de solicitud: ' + formatDate(row["item"].request_date) }}</p>
										<p>{{row["item"].created_at === null ? 'Sin fecha' : 'Fecha de creacion: ' + formatDate(row["item"].created_at) }}</p>
									</b-col>
									<b-col
										sm="9"
									>
										<b-table
											class="data-table"
											striped
											hover
											bordered
											responsive
											show-empty
											:items="Object.values(getFetchedRequestsElementsById(row['item'].id, 'historical_elements'))"
											:fields="insideFields"
											empty-filtered-text="No hay datos que concuerden con tu búsqueda"
										>
											<template #cell(4)="rowInside">
												<b-row class="wordwrap">
													<b-col v-if="!readMore[row['item'].folio + ' ' + rowInside.index]">
														<div v-if="!(rowInside.item[4] === null)">
															<p v-if="rowInside.item[4].length > 10">
																{{rowInside.item[4].slice(0, 9)}}... <b-link class="font-weight-bold" @click="showMore(row['item'].folio + ' ' + rowInside.index)" style="color: black;">Ver más</b-link>
															</p>
															<p v-else>
																{{rowInside.item[4]}}
															</p>
														</div>
													</b-col>
													<b-col v-if="readMore[row['item'].folio + ' ' + rowInside.index]">
														{{rowInside.item[4]}} <b-link class="font-weight-bold" href="" @click="showLess(row['item'].folio + ' ' + rowInside.index)" style="color: black;">Ver menos</b-link>
													</b-col>
												</b-row>
											</template>
										</b-table>
									</b-col>
                                </b-row>
                            </template>
						</b-table>
						<b-pagination
							v-model="currentPageHistorical"
							:total-rows="rows(historicalRequests)"
							:per-page="perPage"
							aria-controls="my-table"
							class="mt-4 customPaginationHistorical"
						>
						</b-pagination>
					</b-col>
					<b-col v-else>
						<p class="text-center">Por el momento no hay datos disponibles</p>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
    <div v-else>
      <div class="m-5 display-4 text-sm">No hay solicitudes para este bien</div>
    </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'Historical',
	data() {
		return {
			filter: null,
			filterOn: [],
			tab: 1,
			perPage: 4,
			currentPageActive: 1,
			currentPageFinished: 1,
			currentPageHistorical: 1,
			readMore: {},
			fields: [
				{
					key: 'requests',
					label: 'Solicitudes:',
					sortable: true,
				},
			],
			insideFields: [
				{
					key: '0',
					label: 'Lugar',
					sortable: false,
				},
				{
					key: '1',
					label: 'Item',
					sortable: false,
				},
				{
					key: '2',
					label: 'Item Detalle',
					sortable: false,
				},
				{
					key: '3',
					label: 'Problema',
					sortable: false,
				},
				{
					key: '4',
					label: 'Observación',
					sortable: false,
				},
			],
		}
	},
	methods: {
		rows(data) {
			return data.length
		},
		onFiltered(filteredItems) {
			this.currentPage = 1
			return this.rows(filteredItems)
		},
		formatDate(date){
			return new Date(date.replace('-', '/').substring(0, 10)).toLocaleString('es-CL').substring(0, 10) + " " + date.substring(11, 19)
		},
		showMore(id) {
            this.$set(this.readMore, id, true);
        },
        showLess(id) {
            this.$set(this.readMore, id, false);
        },
	},
	props: {
		activeRequests: { type: Array, default: null },
		finishedRequests: { type: Array, default: null },
		historicalRequests: { type: Array, default: null },
		activeRequestElements: { type: Object, default: null },
		finishedRequestElements: { type: Object, default: null },
		historicalRequestElements: { type: Object, default: null },
		isActiveEnabled: {type: Boolean, default: false },
		isFinishedEnabled: {type: Boolean, default: false },
		isHistoricalEnabled: {type: Boolean, default: false },
	},
	computed: {
		...mapGetters('assets', [
			'getFetchedRequestsElementsById',
        ]),
	}
}
</script>

<style>
.customPaginationHistorical > li > button {
	color: black !important;
	
	border: 0px !important;
	margin: 0px !important;
}

.customPaginationHistorical > li.active > button
{
	border: 0px;
	color: black !important;
	background-color: rgba(223, 237, 241, 1) !important;
}
.customPaginationHistorical > li.disabled > button
{
	border: 0px;
	color: red !important;
	background-color: rgba(223, 237, 241, 1) !important;
}
.customPaginationHistorical > li
{
	margin: 0px !important;
	border: 0px !important;
	background-color: transparent !important;
}

.page-link{
	background-color: transparent !important;
}

@media (max-width: 800px) {
	.page-link{
		padding: 8px !important;
	}
}
</style>
