<template>
  <transition 
    name="mobysuite-aftersale-client-modal-fade"
  >
    <div 
      class="mobysuite-aftersale-client-modal-backdrop"
    >
      <div
        class="mobysuite-aftersale-client-modal"
        role="dialog"
        aria-labelledby="mobysuite-aftersale-client-modalTitle"
        aria-describedby="mobysuite-aftersale-client-modalDescription"
      >
        <header 
          class="mobysuite-aftersale-client-modal-header"
          id="mobysuite-aftersale-client-modalTitle"
        >
          <slot
            name="header"
          >
          </slot>
          <button
            type="button"
            class="mobysuite-aftersale-client-btn-close"
            @click="close"
            aria-label="Close images-mobysuite-aftersale-client-modal"
          >
            x
          </button>
        </header>

        <section 
          class="mobysuite-aftersale-client-modal-body"
          id="mobysuite-aftersale-client-modalDescription"
        >
          <slot
            name="body"
          >
            <div class="mobysuite-aftersale-client-imgsPreview-modal" v-show="images">
                <b-row
                    class="justify-content-md-center"
                >
                    <b-col
                        lg="6"
                        sm="12"
                        v-for="(img, i) in images" :key="i"
                    >
                        <div class="imageHolder" >
                            <img :src="img" />
                            <span class="delete" style="color: white;" @click="deleteImg(i, rowIndex)">
                                <svg
                                    class="icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                </svg>
                            </span>
                        </div>
                    </b-col>
                </b-row>
            </div>
          </slot>
        </section>

        <footer 
          class="mobysuite-aftersale-client-modal-footer"
        >
          <slot
            name="footer"
          >
          </slot>
            <b-row
              class="justify-content-md-center text-center"
            >
              <b-col
                lg="12"
                sm="12"
                class="my-2"
              >
                <button
                  type="button"
                  class="btn-primary"
                  @click="close"
                  aria-label="Close mobysuite-aftersale-client-modal"
                >
                  Cerrar
                </button>
              </b-col>
            </b-row>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'ImagesModal',
  methods: {
    close() {
      this.$emit('close');
    },
    deleteImg(index) {
      this.getAddedRequirements[this.rowIndex].request_element_images.splice(index, 1)
		},
  },
  props: {
		images: { type: Array, default: null },
		rowIndex: { type: Number, default: null },
	},
	computed: {
		...mapGetters('elements', [ 
      'getAddedElements',
      'getAddedRequirements',
    ]),
  },
}
</script>

<style>

@media (max-width: 1000px) {
  .mobysuite-aftersale-client-modal {
    width: 100% !important;
    max-height: 80% !important;
    height: auto;
  }
}
</style>