<template>
  <transition 
    name="mobysuite-aftersale-client-modal-fade"
  >
    <div 
      class="mobysuite-aftersale-client-modal-backdrop"
    >
      <div
        class="mobysuite-aftersale-client-modal"
        role="dialog"
        aria-labelledby="mobysuite-aftersale-client-modalTitle"
        aria-describedby="mobysuite-aftersale-client-modalDescription"
      >
        <header 
          class="mobysuite-aftersale-client-modal-header"
          id="mobysuite-aftersale-client-modalTitle"
        >
          <button
            type="button"
            class="mobysuite-aftersale-client-btn-close"
            @click="actionOnSuccessType()"
            aria-label="Close mobysuite-aftersale-client-modal"
            v-if="!hasAutomaticScheduling"
          >
            x
          </button>
        </header>

        <section 
          class="mobysuite-aftersale-client-modal-body"
          id="mobysuite-aftersale-client-modalDescription"
        >
          <slot
            name="body"
          >
            <b-row class="justify-content-md-center">
              <b-col cols="12" style="text-align: center;">
                <b-icon icon="check2-circle" class="success-view-icon-style"></b-icon>
                <p class="mt-4" v-if="successType == 'recoverPassword'">
                  {{message}} <strong>{{hideEmail()}}</strong>
                </p>
                <p class="mt-4" v-else>
                  {{message}}
                </p>
                <p v-if="successType == 'recoverPassword'">
                  <br>
                  <br>
                  En caso de tener algún otro problema, contactar al correo de atención a clientes de tu inmobiliaria <strong v-if="getSupportEmail != null">{{getSupportEmail}}</strong>
                </p>
              </b-col>
            </b-row>
          </slot>
        </section>

        <footer 
          class="mobysuite-aftersale-client-modal-footer"
        >
          <slot
            name="footer"
          >
          </slot>
          <b-row
            class="justify-content-md-center text-center px-4"
          >
            <b-col
              lg="3"
              sm="6"
              class="py-3"
              v-if="hasAutomaticScheduling && successType == 'request'"
            >
              <button
                type="button"
                class="btn-primary"
                @click="showAutomaticSchedulingModal()"
                aria-label="Close mobysuite-aftersale-client-modal"
              >
                Agendar hora de visita
              </button>
            </b-col>
            <b-col
              lg="3"
              sm="6"
              class="py-3"
              v-else
            >
              <button
                type="button"
                class="btn-primary"
                @click="actionOnSuccessType()"
                aria-label="Close mobysuite-aftersale-client-modal"
              >
                {{buttonMessage}}
              </button>
            </b-col>

          </b-row>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SucessView',
  data() {
    return {
    }
  },
  created() {
    if (this.successType != 'recoverPassword')
    {
      this.getAllScheduledDatesByAsset({asset_id: this.getSelectedAsset.asset_id})
    }
  },
  props: {
    title: { type: String, default: null },
    message: { type: String, default: null },
    buttonMessage: { type: String, default: null },
    successType: { type: String, default: null },
    hasAutomaticScheduling: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('assets', [
      'getSelectedAsset',
      'getSelectedProject',
		]),
    ...mapGetters('general', [
      'getClientRecoverPassEmail',
      'getSupportEmail'
    ]),
  },
  methods: {
    ...mapMutations('elements', [
      'setSelectedPlace',
      'setSelectedItem',
      'setSelectedDetail',
      'setSelectedProblem',
      'setProcessStage',
      'setAddedElements',
      'setAddedRequirements',
    ]),
    ...mapMutations('general', [
      'setShowSuccessView',
      'setShowAutomaticScheduling',
      'setMenuOption',
      'setShowRecoverPassword',
    ]),
    ...mapActions('assets', [
      'getRequestsByAsset',
      'getAllScheduledDatesByAsset',
    ]),
    actionOnSuccessType(){
      if (this.successType == 'request' || this.successType == 'request-success')
      {
        this.setSelectedPlace(null)
        this.setSelectedItem(null)
        this.setSelectedDetail(null)
        this.setSelectedProblem(null)
        this.setProcessStage('places')
        this.setAddedElements([])
        this.setAddedRequirements([])
        this.getRequestsByAsset({asset: this.getSelectedAsset, project: this.getSelectedProject})
        this.setMenuOption('historical')
      }
      if (this.successType == 'profile')
      {
        this.setMenuOption('profile')
      }
      if (this.successType == 'automatic')
      {
        this.setSelectedPlace(null)
        this.setSelectedItem(null)
        this.setSelectedDetail(null)
        this.setSelectedProblem(null)
        this.setAddedElements([])
        this.setAddedRequirements([])
        this.getRequestsByAsset({asset: this.getSelectedAsset, project: this.getSelectedProject})
        this.setMenuOption('historical')
        this.close()
        this.setShowAutomaticScheduling(false)
      }
      if (this.successType == 'recoverPassword')
      {
        this.close()
        this.setShowRecoverPassword(false)
      }
      this.close()
    },
    showAutomaticSchedulingModal(){
      this.setSelectedPlace(null)
      this.setSelectedItem(null)
      this.setSelectedDetail(null)
      this.setSelectedProblem(null)
      this.setAddedElements([])
      this.setAddedRequirements([])
      this.getRequestsByAsset({asset: this.getSelectedAsset, project: this.getSelectedProject})
      this.close()
      this.setShowAutomaticScheduling(true)
    },
    close() {
      this.$emit('close')
      this.setShowSuccessView(false)
    },
    hideEmail() {
			let email = this.getClientRecoverPassEmail
			// let res = email.replace(/(\w{1})[\w.-]+@([\w.]+\w)/, "$1***@$2")
      let hide = email.split("@")[0].length - 2
      var r = new RegExp(".{"+hide+"}@", "g")
      let ast = ('*'.repeat(hide+2)) + "@"
      email = email.replace(r, ast );
      console.log(email)
			return email
		}
  },
}
</script>

<style>
  .success-view-icon-style{
    transform: scale(8);
    margin: 45px;
    color: #00718C;
  }
</style>